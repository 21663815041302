<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </li>
            <li>
              <a>{{ $t("user_group.users") }}</a>
            </li>
            <li>{{ $t("user_group.title") }}</li>
            <li>{{ $t("user_group.create") }}</li>
          </ul>
        </nav>
      </div>

      <div class="card" v-if="loading">
        <default-loading></default-loading>
      </div>
      <div class="card" v-else>
        <div v-for="error in formErrors" v-if="formErrors" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color:red;">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </div>

      <div uk-grid>
        <div class="uk-width-1-4@m">
          <!--uk-flex-last@m-->
          <nav class="responsive-tab style-3 setting-menu card"
            uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">
            <ul class="uk-tab-left uk-tab"
              uk-switcher="connect: #user-group-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
              <li class="uk-active">
                <a href="#">{{ $t("user_group.information") }}</a>
              </li>
              <li>
                <a href="#">{{ $t("user_group.group_user") }}</a>
              </li>
            </ul>
          </nav>
        </div>

        <div class="uk-width-expand">
          <ul class="uk-switcher uk-margin" id="user-group-tab">
            <li>
              <div class="card rounded">
                <div class="p-3 d-flex justify-content-between">
                  <h4 class="mb-0">{{ $t("user_group.information") }}</h4>
                </div>
                <hr class="m-0" />
                <div class="course-card-body">
                  <form class="regular-form" action="">
                    <div class="uk-grid-collapse uk-child-width-1-1@s" uk-grid>
                      <div class="position-relative">
                        <div class="form-group row">
                          <div class="col-md-12">
                            <label class="col-form-label" for="name">{{ $t("user_group.list.name")
                            }}<span class="required">*</span></label>
                            <input type="text" class="form-control" id="name" name="name" placeholder=""
                              v-model="form.name" required="" />
                          </div>
                        </div>
                        <div class="form-group row mt-3 input-checkbox">
                          <div class="col-md-12">
                            <div class="d-flex">
                              <input id="status" type="checkbox" v-model="form.status" />
                              <label class="ml-2" for="status">{{
                                $t("user_group.list.active")
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </li>
            <li>
              <div class="card rounded">
                <div class="p-3 d-flex justify-content-between align-items-center">
                  <h4 class="mb-0">{{ $t("user_group.group_user") }}</h4>

                  <div class="uk-card-header p-0">
                    <div class="float-right">
                      <a href="#modal-overflow" uk-toggle type="button" class="uk-button uk-button-primary small"><i
                          class="uil-user-plus"></i>
                        {{ $t("user_group.add_user_group") }}</a>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="course-card-body">
                  <div class="table-responsive">
                    <table class="table align-items-center">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t("user_group.list.first_lastname") }}
                          </th>
                          <th scope="col">
                            {{ $t("user_group.list.user_title") }}
                          </th>
                          <th scope="col">
                            {{ $t("user_group.list.added_date") }}
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody class="list">
                        <template v-for="(item, index) in form.userGroup">
                          <tr>
                            <th scope="row">
                              <div class="media align-items-center">
                                <div>
                                  <div class="avatar-parent-child" style="width: max-content">
                                    <img alt="Image placeholder" :src="item.photo_link" class="avatar  rounded-circle" />
                                    <span class="avatar-child avatar-badge bg-success"></span>
                                  </div>
                                </div>
                                <div class="media-body ml-4">
                                  <a href="#" class="name h6 mb-0 text-sm">{{
                                    item.fullname
                                  }}</a>
                                  <small class="d-block font-weight-bold">#{{ item.id }}</small>
                                </div>
                              </div>
                            </th>
                            <td>{{ item.title }}</td>
                            <td>{{ item.created_at }}</td>
                            <td class="text-right">
                              <!-- Actions -->
                              <div class="actions ml-3">
                                <a uk-toggle="target: #modal-profile" class="btn btn-icon btn-hover btn-sm btn-circle"
                                  uk-tooltip="Kullanıcının Profili" title="" aria-expanded="false">
                                  <i class="uil-user "></i>
                                </a>
                                <a @click="removeUserGroup(item.id)" class="btn btn-icon btn-hover btn-sm btn-circle"
                                  uk-tooltip="Kullanıcıyı Gruptan Çıkar" title="" aria-expanded="false">
                                  <i class="uil-minus-circle text-danger"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="uk-modal-footer uk-text-right bggray">
        <button @click="$router.push({ name: 'company.user.group' })"
          class="uk-button uk-button-default bg-white small mr-3">
          {{ $t("general.back") }}
        </button>
        <button @click="saveForm()" class="uk-button uk-button-primary small" type="button">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <AddToGroupModal @getList="getList($event)"></AddToGroupModal>
  </div>
</template>

<script>
import module, {
  BASE_URL,
  CREATE_ITEM,
  ERROR,
  MODULE_NAME,
  SUCCESS,
  LOADING,
} from "@/core/services/store/user_group.module";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import AddToGroupModal from "@/view/company/User/Group/AddToGroupModal";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

export default {
  name: "UserGroupAdd",
  components: { Pagination, AddToGroupModal, DefaultLoading },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
  },
  data() {
    return {
      form: {
        name: "",
        status: true,
        userGroup: [],
      },
      formErrors: [],
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      filter: {
        search: null,
        orderBy: null,
        sort: null,
      },
      pagination: null,
    };
  },

  computed: {
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    loading() {
      return store.getters[MODULE_NAME + "/" + LOADING];
    },
  },
  methods: {
    saveForm() {
      let self = this;
      let form = self.form;

      self.formErrors = [];
      if (form.name.length == 0) {
        self.formErrors.push(self.$t("user_group.required.name"));
        self.scrollToTop();
        return false;
      }

      let ids = [];

      form.userGroup.forEach((item) => {
        ids.push(item.id);
      });

      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: {
            name: form.name,
            status: form.status,
            user_ids: ids,
          },
        })
        .then(() => {
          if (!this.error) self.$router.push({ name: "company.user.group" });
        });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getList(value) {
      this.form.userGroup = value;
    },
    removeUserGroup(index) {
      const deletedUserIndex = this.form.userGroup.findIndex(item => item.id === index)
      this.form.userGroup.splice(deletedUserIndex, 1);
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
<style scoped>
.uk-alert {
  margin: 0;
}

.no-padding {
  padding: 0;
}
</style>
